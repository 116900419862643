<template>
  <v-btn
    v-if="create"
    @click="createDeliveryRequest"
    color="primary"
    small
  >
    {{ 'order.action.delivery_request'| t }}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  methods: {
    createDeliveryRequest() {
      this.$apopup.base({
        width: '550px',
        title: this.$t('order.action.delivery_request'),
        bodySlot: () => import('./createDeliveryRequestPopup.vue'),
        order: this.order,
        disabledApply: data => {
          if(!data) return false
          // 非常溫溫層不可使用150cm
          if(data.temperature != this.$deliveryConstants.TEMPERATURE_NORMAL && data.size >= 150) return true
          return false
        },
        applyCallback: this.request,
      })
    },
    async request(params) {
      try {
        await this.$api.collection.deliveryApi.createThirdPartyOrder(this.order.id, params)
        this.$snotify.success(null, this.$t('save.successfully'))
        this.redirect()
      } catch (error) {
        console.error(error)
        let message = this.$t('error.unexpect')
        if(error.status === 404) message = this.$t('order.notify.not_found')
        this.$snotify.error(message, this.$t('save.failure'))
        if(error.status === 409) this.redirect()
      } finally {
        this.formActions.initForm()
      }
    },
    redirect() {
      this.$router.push({
        query: { tab: 'delivery_request_logs' }
      })
    },
  },
  computed: {
    feature() {
      return this.$store.getters['base/feature']
    },
    create() {
      if(!this.formData) return false
      if(this.formData.status == this.$orderConstants.STATUS_VOID) return false
      return this.feature.thirdPartyDeliveryTypes[this.deliveryType] === true
    },
    deliveryType() {
      if(!this.order) return null
      return this.order.delivery.type
    },
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    order() {
      return this.formData
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
